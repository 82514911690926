import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import { Typography } from '@material-ui/core';
import 'emoji-mart/css/emoji-mart.css';
import smile from '../../assets/icons/smile.png';
import { Picker } from 'emoji-mart';
import { updateTextInput } from '../../actions/text.action';
import { submitAnswer } from '../../utilities/answer';
import { advanceMessage } from '../../utilities/socket';
import { STORE } from '../../store';

const styles = theme => {
  return ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      background: 'white',
      borderTop: `1px solid  ${theme.palette.primary.main}`
    },
    powered: {
      padding: 3,
      // margin: 'auto',
      width: '100%',
      // left: 0,
      right: 0,
      textAlign: 'center',
      // cursor: 'pointer',
      fontSize: 12
    },
    icon: {
      verticalAlign: 'middle',
      fill: '#FFC107',
      fontSize: '1.2rem'

    },
    input: {
      border: 'none !important',
      fontWeight: 600,
      color: '#ccc'
    }
  });
};

class TextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      emoji: false,
      inputValue: ''
    };
    this.timeout = null;
    window.inputRef = React.createRef();
  };
  whitelabel = STORE.getState();

  componentDidMount() {
    // scroll();
  }

  toggleEmoji = () => {
    this.setState(prev => {
      return {
        emoji: !prev.emoji
      };
    });
  };
  chooseEmoji = (event) => {
    this.props.updateTextInput({
      value: this.props.textInput.value + event.native,
    });
  };

  focus = () => {
    // scroll( 100 );
  };

  handleChange = (event) => {
    this.setState({
      text: event.target.value
    });
    this.props.updateTextInput({
      value: event.target.value
    });
    // userTyping(this.props.environment.uuid, "start")
    if (localStorage.getItem('agentId')) {
      advanceMessage({ uuid: this.props.environment.uuid, advanceMessage: event.target.value })
    }
    this.keyDown(event);
  };

  keyDown = (event) => {
    if (event.key === 'Enter') {
      this.send();
    }
      this.setState({
        emoji: false
      });

      // set selected value to empty string in view component
      this.props.resetSelectedValue()
  };


  send = () => {
    
    if (this.props.selectedValue !== undefined || this.props.selectedValue !== null) {
      submitAnswer(this.props.selectedValue, this.props.textInput.original);
      this.setState({
        emoji: false
      });
      this.props.resetSelectedValue();
    }
    if (this.props.textInput.value.trim() !== '') {
      submitAnswer(this.props.textInput.value, this.props.textInput.original);
      this.setState({
        emoji: false
      });
    }
  };

  isPoweredBy = () => {
    if (this.props.environment.agencyData.type !== 'WHITE_LABEL') {
      return true;
    }
    return (this.props.environment.agencyData?.isAgencyConfigurationEnabled?.poweredBy &&
      this.props?.environment?.agencyData?.meta?.poweredBy) || false;
  }

  getAgencyMeta = (type) => {
    const isWhitelabelAgency = this.props.environment.agencyData.type === 'WHITE_LABEL';
    switch (type) {
      case 'websiteUrl':
        return isWhitelabelAgency
          ? this.props.environment.agencyData.meta?.websiteUrl || ''
          : `https://ecommercebot.com/?utm_source=referral&utm_medium=${window.location.host}&utm_campaign=chat_window`;
      case 'poweredBy':
        return isWhitelabelAgency
          ? this.props?.environment?.agencyData?.meta?.poweredBy || ''
          : (<span> Chat {<FlashOnIcon className={this.props.classes.icon} />} by EcommerceBot </span>);
      default:
        return '';
    }
  }
  render(event) {
    if (this.props.selectedValue) {
      window.inputRef.current.focus();
    }
    const isWhitelabeled = this.props.environment.whitelabel?.active || this.props.environment.whitelabel?.byAgency;
    if (isWhitelabeled) {
      return this.displayWhitelabled();
    }
    return this.displayBasic();
  }



  displayWhitelabled() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Input
          inputRef={window.inputRef}
          onClick={this.focus}
          disabled={!this.props.textInput.status}
          // disabled={false}
          className={classes.input}
          fullWidth
          id="inputMessage"
          variant="outlined"
          placeholder={this.props.textInput.status ? (this.props.environment?.chatMenu.placeholder || 'Type your answer') : ''}
          type="text"
          value={this.props.selectedValue ? this.props.selectedValue : this.props.textInput.value}
          onKeyUp={(e) => this.keyDown(e)}
          onChange={(e) => this.handleChange(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={!this.props.textInput.status}
                  edge="end"
                  aria-label="Emoji picker"
                  onClick={this.toggleEmoji}
                  color="primary"
                >
                  <img src={smile} alt="emoji" />
                </IconButton>
                <IconButton
                  disabled={false}
                  edge="end"
                  aria-label="toggle password visibility"
                  onClick={this.send}
                  color="primary"
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {this.state.emoji ?
          <Picker title={'Choose emoji'} style={{ position: 'fixed', bottom: '77px', right: '10px' }}
            onSelect={(e) => this.chooseEmoji(e)} /> : ''}
      </div>
    );
  }

  displayBasic() {
    const { classes } = this.props;
    return (
      <div className={classes.root}
        style={{ height: '86px' }}>
        <Input
          inputRef={window.inputRef}
          onClick={this.focus}
          disabled={!this.props.textInput.status}
          // disabled={false}
          className={classes.input}
          fullWidth
          id="inputMessage"
          variant="outlined"
          placeholder={this.props.textInput.status ? (this.props.environment?.chatMenu.placeholder || 'Type your answer') : ''}
          type="text"
          value={this.props.selectedValue ? this.props.selectedValue : this.props.textInput.value}
          onKeyUp={(e) => this.keyDown(e)}
          onChange={(e) => this.handleChange(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={!this.props.textInput.status}
                  edge="end"
                  aria-label="Emoji picker"
                  onClick={this.toggleEmoji}
                  color="primary"
                >
                  <img src={smile} alt="emoji" />
                </IconButton>
                <IconButton
                  disabled={false}
                  edge="end"
                  aria-label="toggle password visibility"
                  onClick={this.send}
                  color="primary"
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {this.state.emoji ?
          <Picker title={'Choose emoji'} style={{ position: 'fixed', bottom: '77px', right: '10px' }}
            onSelect={(e) => this.chooseEmoji(e)} /> : ''}

        {this.isPoweredBy() &&
          <Paper
            elevation={0}
            component={'div'}
            className={classes.powered}
            style={{}}
          >
            <Typography component={'p'}
              style={{
                fontSize: 14,
                color: '#1661f5',
                display: 'block'
              }}>
              <a
                href={this.getAgencyMeta('websiteUrl')}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'none',
                  color: '#1661f5'
                }}>
                {this.getAgencyMeta('poweredBy')}
              </a>
            </Typography>
            <p></p>
          </Paper>
        }

      </div>
    );
  }
}

const mapStateToProps = state => ({
  textInput: state.text,
  translation: state.translation,
  environment: state.environment
});

export default connect(mapStateToProps, {
  updateTextInput
})(withStyles(styles)(TextField));
