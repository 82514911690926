import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Index from './views';
import { Provider } from 'react-redux';
import { STORE } from './store';

ReactDOM.render(
  <Provider store={ STORE }>
    <Index/>
  </Provider>,
  document.getElementById( 'ShopifyBot-messenger-root' )
);

