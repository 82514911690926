
import { ADD_ATTRIBUTE, ADD_ATTRIBUTES } from '../actions/actions';

export const attributeReducer = (state = [], action) => {
    if (action.type === ADD_ATTRIBUTE) {
        return [
            ...state.filter(attribute => (attribute.key !== action.payload.key)),
            { ...action.payload }
        ];
    } else if (action.type === ADD_ATTRIBUTES) {
        const attribute = state.filter(attr => !((action.payload || []).some(a => a.key === attr.key)));
        return [
            ...attribute,
            ...action.payload
        ];
    }
    return state;


};
