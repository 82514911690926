import React from 'react';
import { createMuiTheme, IconButton, ThemeProvider, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Header from '../components/Header';
import Typing from '../components/Typing';
import SkipBack from '../components/SkipBack';
import TextField from '../components/TextInput';
import renderComponent from '../utilities/render-component';
// import CancelIcon from '@material-ui/icons/Close';
import { STORE } from '../store';
import { loadFlow } from '../actions/flow.action';
import { getAttributesList, getDeviceDetails, getDynamicFont, getIpDetails, scroll } from '../utilities';
import { updateMeta } from '../actions/meta.action';
import { renderQuestion } from '../utilities/question';
import { updateUUID, addInstallation } from '../utilities/save-message';
import { makeSocketConnection, updateMeta as updateMetaToSocket } from '../utilities/socket';
import { updateTextInput } from '../actions/text.action';
import { updateAI, updateAIKey } from '../actions/ai';
import { updateEnvironment } from '../actions/environment.action';
import Suggestion from '../components/Suggestion';
import { submitAnswer } from '../utilities/answer';
import { updateSingleMessage } from '../actions/message.action';

const styles = () => ({
  chatArea: {
    marginTop: 65,
    overflowY: 'auto',
    background: 'white;',
    marginBottom: 86,
    position: 'relative'
  },
  video: {
    width: 'auto !important',
    margin: 10
  },
  inputField: {
    width: '100%',
    height: '35px',
    marginBottom: '10px',
    borderRadius: '9px',
    paddingLeft: '15px',
  }
});

class Index extends React.Component {

  constructor(props) {
    super(props);
    let secondLastMessage = this.props.messages[this.props.messages.length - 2];
    this.state = {
      selectedValue: '',
      currentPath: window.location.pathname,
      start: false,
      theme: createMuiTheme({
        typography: {
          fontFamily: ['custom-font'],
          fontWeightRegular: '600',
          fontWeightLight: '600'
        },
        palette: {
          primary: {
            main: '#cc1d38'
          },
          secondary: {
            main: '#000000'
          },
          text: {
            primary: '#ffffff'
          }
        },

      }),
      // Form Data New
      formData: {
        firstName: '',
        email: '',
        // phone: '',
        lastName: '',
        checked: true
      },
      contactFormData: { name: sessionStorage.getItem('userName'), email: sessionStorage.getItem('userEmail'), phone: sessionStorage.getItem('userPhone'), question: secondLastMessage?.label },
      error: '',
      submittedData: null,
      contactSubmittedData: null,
      count: 0
    };
    window.chatAreaRef = React.createRef();
    this.handleValueChange = this.handleValueChange.bind(this);
    this.resetSelectedValue = this.resetSelectedValue.bind(this);
    // Form Data New
    this.handleChange = this.handleChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleContactSubmit = this.handleContactSubmit.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  componentDidMount() {
    // Check for dynamic font
    if (this.props?.design?.fontFamilyLink) {
      getDynamicFont(this.props?.design?.fontFamilyLink);
    }

    // Logic to auto scroll when launcher icon change display block from none
    let frame = window.parent.document.getElementById('ShopifyBot-messenger');
    var observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutationRecord) {
        scroll()
      });
    });
    if (frame) {
      observer.observe(frame, { attributes: true, attributeFilter: ['style'] });
    }

    window.addEventListener('message', (event) => {
      if (event.data.origin === 'ShopifyBot') {
        if (event.data.action === 'start') {
          if (!this.props.messages.length) {
            if (!this.state.start) {
              this.setState({
                start: true
              });
              // renderQuestion();
              // updateMetaToSocket();
              // addInstallation();
            }
          }
        }
      }
    });

    if (localStorage.getItem('ShopifyBot_User_uuid') && window.parent.ShopifyBotData?.uuid !== localStorage.getItem('ShopifyBot_User_uuid')) {
      STORE.dispatch(updateEnvironment({
        ...STORE.getState().environment,
        uuid: localStorage.getItem('ShopifyBot_User_uuid')
      }));
    }

    if (window.parent.ShopifyBotData?.ai?.chatGPT?.key) {
      STORE.dispatch(updateAIKey());
    }

    STORE.dispatch(loadFlow());
    if (this.props.environment.isLanding) {
      this.setState({
        start: true
      });
      renderQuestion();
    }
    this.setState({
      theme: {
        ...this.state.theme,
        palette: {
          ...this.state.theme.palette,
          primary: {
            main: this.props.design.themeColor
          },
          text: {
            ...this.state.theme.palette.text,
            primary: this.props.design.textColor
          }
        }
      }
    });

    const root = document.documentElement;
    root.style.setProperty('--primary-theme-color', this.props.design.themeColor);
    getIpDetails()
      .then((result) => {
        this.props.updateMeta({
          ...result,
          ...getDeviceDetails(),
          location: result.city
        });
        if (!this.props.environment.preview) {
          makeSocketConnection({
            ...result,
            ...getDeviceDetails(),
            location: result.city,
            user: this.props.environment._user,
            uuid: this.props.environment.uuid
          });
        }
        updateUUID({
          ...result,
          ...getDeviceDetails(),
          location: result.city,
          url: window.location.href
        }).then().catch();
        window.parent.postMessage({
          origin: 'ShopifyBot',
          action: 'rendered'
        }, '*');
      });



    const state = STORE.getState();
    if (state.environment.liveChat) {
      STORE.dispatch(updateTextInput({
        status: true,
      }));
    }

    getAttributesList(state.environment._user)
      .then((attributesList) => {

        STORE.dispatch(updateEnvironment({
          customAttributesList: attributesList
        }));

      }).catch((error) => {
        console.log(`error in getAttributesList ${error}`);
      })
  }

  componentDidUpdate(prevProps, prevState) {
    let lastMessage = this.props.messages[this.props.messages.length - 1];
    let secondLastMessage = this.props.messages[this.props.messages.length - 2];
    if (lastMessage && lastMessage.showContactForm && this.state.count === 0) {
      this.setContactQuestion(secondLastMessage?.label);
      this.setState({ count: 1 });
    }
  }

  handleValueChange(value) {
    this.setState({ selectedValue: value });
  }

  resetSelectedValue() {
    // reset the selected value state
    this.setState({ selectedValue: "" })
  }

  // Form addition start
  handleChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value
      }
    }));
  }

  handleCheckboxChange(event) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        'checked': event.target.checked
      }
    }));
  }


  setContactValue(question) {
    this.setState((prevState) => ({
      contactFormData: {
        ...prevState.contactFormData,
        name: sessionStorage.getItem("userName"),
        email: sessionStorage.getItem("userEmail"),
        phone: sessionStorage.getItem("userPhone"),
        question
      }
    }));
  }

  setContactQuestion(question) {
    this.setState((prevState) => ({
      contactFormData: {
        ...prevState.contactFormData,
        question
      }
    }));
  }

  handleContactChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      contactFormData: {
        ...prevState.contactFormData,
        [name]: value
      }
    }));
  }

  clearError() {
    setTimeout(() => {
      this.setState({ error: '' });
      this.setState((prevState) => ({
        ...prevState,
        error: ''
      }));
    }, 3000);
  }

  handleInputChange = (event) => {

    this.setState((prevState) => ({
      contactFormData: {
        ...prevState.contactFormData,
        question: event.target.value
      }
    }));
  };

  handleSubmit(event) {
    let name = `${this.state.formData.firstName} ${this.state.formData.lastName}`.trim();
    let email = this.state.formData.email;
    // let phone = this.state.formData.phone;
    event.preventDefault();
    if (!this.state.formData.email || (!name)) {
      this.clearError();
      return this.setState({ error: 'Name or Email cannot be empty' });
    }
    if (!this.isValidEmail(this.state.formData.email)) {
      this.clearError();
      return this.setState({ error: 'Please enter a valid email' });
    }
    if (!this.state.formData.checked) {
      this.clearError();
      return this.setState({ error: 'Please accept the terms and conditions' });
    }
    this.setState({
      submittedData: `${this.state.formData.firstName} ${this.state.formData.lastName}`.trim(),
      formData: { firstName: '', email: '', lastName: '' } // Clear the form fields
    });
    updateUUID({
      name: `${this.state.formData.firstName} ${this.state.formData.lastName}`.trim(),
      email: this.state.formData.email,
      // phone: this.state.formData.phone
    }).then(res => {
      sessionStorage.setItem("userName", name);
      sessionStorage.setItem("userEmail", email);
      // sessionStorage.setItem("userPhone", phone);
      let secondLastMessage = this.props.messages[this.props.messages.length - 2];
      this.setContactValue(secondLastMessage?.label)
      renderQuestion();
      updateMetaToSocket();
      addInstallation();
      this.setState({ error: '' });
    })
      .catch(err => {
        this.setState({ error: err.message });
        this.clearError();
      });
  }
  // Form Addition End

  handleContactSubmit(event) {
    event.preventDefault();
    if (!this.state.contactFormData.email || (!this.state.contactFormData.name)) {
      this.clearError();
      return this.setState({ error: 'Name or Email cannot be empty' });
    }
    if (!this.isValidEmail(this.state.contactFormData.email)) {
      this.clearError();
      return this.setState({ error: 'Please enter a valid email' });
    }
    if (!this.state.contactFormData.question) {
      this.clearError();
      return this.setState({ error: 'Question cannot be empty' });
    }
    let secondLastMessage = this.props.messages[this.props.messages.length - 2];
    this.updateMetaData(this.state.contactFormData.name, this.state.contactFormData.email, this.state.contactFormData.phone);
    submitAnswer(secondLastMessage?.label, '', null, true)
      .then(res => {
        renderQuestion();
        updateMetaToSocket();
        addInstallation();
        this.setState({ count: 0 });
        this.setState({ error: '' });
      })
      .catch(err => {
        this.setState({ error: err.message });
        this.clearError();
      });
  }

  updateMetaData(name, email, phone) {
    updateUUID({
      name: name,
      email: email,
      phone
    }).then(res => {
      sessionStorage.setItem("userName", name);
      sessionStorage.setItem("userEmail", email);
      sessionStorage.setItem("userPhone", phone);
      renderQuestion();
      updateMetaToSocket();
      addInstallation();
      this.setState({ error: '' });
    })
      .catch(err => this.setState({ error: err.message }));
  }

  isValidEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }
  // Form Addition End

  resetSelectedValue() {
    // reset the selected value state
    this.setState({ selectedValue: "" })
  }

  render() {
    // Form addition start
    let uuid = this.props.environment.uuid;

    const { formData, submittedData } = this.state;
    const { contactFormData, contactSubmittedData } = this.state;

    // Form Addition End



    let lastMessage = this.props.messages[this.props.messages.length - 1];
    let secondLastMessage = this.props.messages[this.props.messages.length - 2];
    let buttons = (lastMessage?.suggestions || []).length ? (lastMessage.suggestions || []).map((suggestion) => {
      return ({ title: suggestion })
    }) : []
    scroll();
    const { classes } = this.props;
    const isWhitelabeled = this.props.environment.whitelabel?.active || this.props.environment.whitelabel?.byAgency;

    return (this.props.messages.length && !lastMessage.showContactForm) ? (
      <ThemeProvider theme={this.state.theme}>
        {this.props.environment.isLanding ? null : <Header messages={this.props.messages} />}
        <div ref={window.chatAreaRef} id="chatArea" className={classes.chatArea} style=
          {{
            background: this.props.design.backgroundColor,
            overflow: this.props.environment.dateTime ? 'hidden' : 'auto',
            marginTop: this.props.environment.isLanding ? '0' : '65px',
            height: this.props.environment.isLanding ? 'calc(100vh - 95px)'
              : isWhitelabeled
                ? 'calc(100vh - 122px)'
                : 'calc(100vh - 151px)'
          }}
        >
          {this.props.messages.map((message, index) => renderComponent(message, index))
          }
          {this.props.environment.typing ? <Typing avatar={this.props.design.avatar} /> : null}
          <SkipBack />
          <Suggestion classes={classes} buttons={buttons} onValueChange={this.handleValueChange} />
        </div>
        <TextField selectedValue={this.state.selectedValue} resetSelectedValue={this.resetSelectedValue} />
      </ThemeProvider>
    ) : lastMessage?.showContactForm ? (
      <ThemeProvider theme={this.state.theme}>
        {this.props.environment.isLanding ? null : <Header messages={this.props.messages} />}
        <div style=
          {{
            height: this.props.environment.isLanding ? 'calc(100vh - 95px)'
              : isWhitelabeled
                ? 'calc(100vh - 122px)'
                : 'calc(100vh - 151px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <form onSubmit={this.handleContactSubmit} style={{ "width": "calc(100vw - 80px)", marginTop: "167px" }}>
            <div style={{
              display: 'flex',
              justifyContent: 'end',
              paddingTop: '2rem',
            }}>
              {/* <IconButton
                onClick={() => {
                  STORE.dispatch(updateSingleMessage({ ...this.props.messages[this.props.messages.length - 1], showContactForm: false }));
                  renderQuestion();
                }}
              >
                <CancelIcon />
              </IconButton> */}
            </div>
            <h3 style={{ textAlign: 'center' }}>Contact us</h3>
            <p style={{ textAlign: 'center' }}>Send over your message here, then we will get back to you as soon as possible! </p>
            {this.state.error && <p style={{ textAlign: 'center', color: 'red' }}>{this.state.error}</p>}
            <div>
              <input
                style={{
                  width: '98%',
                  height: '35px',
                  marginBottom: '10px',
                  borderRadius: '9px',
                  paddingLeft: '15px',
                }}
                type="text"
                name="name"
                placeholder='Name'
                onChange={this.handleContactChange}
                value={contactFormData.name}
              />
            </div>
            <div>
              <input
                style={{
                  width: '98%',
                  height: '35px',
                  borderRadius: '9px',
                  marginBottom: '10px',
                  paddingLeft: '15px',
                }}
                type="email"
                name="email"
                placeholder='Email'
                onChange={this.handleContactChange}
                value={contactFormData.email}
              />
            </div>
            <div>
              <input
                style={{
                  width: '98%',
                  height: '35px',
                  borderRadius: '9px',
                  marginBottom: '10px',
                  paddingLeft: '15px',
                }}
                type="number"
                name="phone"
                placeholder='Phone Number (Optional)'
                onChange={this.handleContactChange}
                value={contactFormData.phone || ''}
              />
            </div>
            <div>
              <input
                style={{
                  width: '98%',
                  height: '35px',
                  borderRadius: '9px',
                  marginBottom: '10px',
                  paddingLeft: '15px',
                }}
                type='text'
                name="question"
                value={ contactFormData?.question || secondLastMessage.label }
                onChange={this.handleContactChange}
                placeholder='Question'
              />
            </div>
            <button style={{
              width: '100%',
              height: '41px',
              borderRadius: '11px',
              backgroundColor: '#118cfc',
              color: '#fff',
              border: "none"
            }} type="submit">Submit</button>
            <p style={{
              color: '#118cfc',
              textDecoration: 'underline',
              textAlign: 'center',
              cursor: 'pointer'
            }} onClick={() => {
              STORE.dispatch(updateSingleMessage({ ...this.props.messages[this.props.messages.length - 1], showContactForm: false }));
              renderQuestion();
            }}>Try asking in another way</p>
          </form>
        </div>
      </ThemeProvider>
    ) : (
      <ThemeProvider theme={this.state.theme}>
        {this.props.environment.isLanding ? null : <Header messages={this.props.messages} />}
        <div style=
          {{
            height: this.props.environment.isLanding ? 'calc(100vh - 95px)'
              : isWhitelabeled
                ? 'calc(100vh - 122px)'
                : 'calc(100vh - 151px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <form onSubmit={this.handleSubmit} style={{ "width": "calc(100vw - 80px)", marginTop: "167px" }}>
            <h2 style={{ textAlign: 'center' }}><u>Before we get started</u></h2>
            <p style={{ textAlign: 'center' }}>Please Provide your information so we can <br /> reply to you if you leave the chat</p>
            {this.state.error && <p style={{ textAlign: 'center', color: 'red' }}>{this.state.error}</p>}
            <div>
              <input
                style={{
                  width: '98%',
                  height: '35px',
                  marginBottom: '10px',
                  borderRadius: '9px',
                  paddingLeft: '15px',
                }}
                type="text"
                name="firstName"
                placeholder='First Name'
                value={formData.firstName}
                onChange={this.handleChange}
              />
            </div>
            <div>
              <input
                style={{
                  width: '98%',
                  height: '35px',
                  borderRadius: '9px',
                  marginBottom: '10px',
                  paddingLeft: '15px',
                }}
                type="text"
                name="lastName"
                placeholder='Last Name'
                value={formData.lastName}
                onChange={this.handleChange}
              />
            </div>
            <div>
              <input
                style={{
                  width: '98%',
                  height: '35px',
                  borderRadius: '9px',
                  marginBottom: '10px',
                  paddingLeft: '15px',
                }}
                type="email"
                name="email"
                placeholder='Email'
                value={formData.email}
                onChange={this.handleChange}
              />
            </div>
            {/* <div>
              <input
                style={{
                  width: '98%',
                  height: '35px',
                  borderRadius: '9px',
                  marginBottom: '10px',
                  paddingLeft: '15px',
                }}
                type="number"
                name="phone"
                placeholder='1234567890'
                value={formData.phone}
                onChange={this.handleChange}
              />
            </div> */}
            <div className="form-group" style={{ marginBottom: '10px' }}>
              {/* <label>
                <input
                  type="checkbox"
                  name="agreeToTerms"
                  checked={formData.checked}
                  onChange={this.handleCheckboxChange}
                  className="form-checkbox"
                />
                I agree to the terms and conditions
              </label> */}
            </div>
            <div>
              {/* <p>
                By proceeding, you agree to the sharing of your data with third parties for the provision, and improvement, of the services.
              </p> */}

              <p>
                By proceeding, you agree to our 
                {/* <a href='https://ecommercebot.com/privacy-policy/' target='_blank'> */}
                Privacy Policy
                {/* </a>  */}
                and 
                {/* <a href='https://ecommercebot.com/terms-conditions/' target='_blank'> */}
                Terms of Service
                {/* </a>.  */}
                Protected by 
                {/* <a href='https://www.freeprivacypolicy.com/blog/recaptcha-privacy-policy/' target='_blank'> */}
                reCAPTCHA
                {/* </a> */}
                and Google's Privacy Policy and Terms of Service.
              </p>
            </div>
            <button style={{
              width: '100%',
              height: '41px',
              borderRadius: '11px',
              backgroundColor: '#118cfc',
              color: '#fff',
              border: "none"
            }} type="submit">Start Chat</button>
          </form>
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  design: state.design,
  messages: state.messages,
  environment: state.environment
});

export default connect(mapStateToProps,
  {
    updateMeta: updateMeta
  })
  (withStyles(styles)(Index));
