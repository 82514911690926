import { NEW_MESSAGE, UPDATE_MESSAGES, UPDATE_SINGLE_MESSAGES, NEW_NOTIFICATION, LIVE_CHAT_NOTIFICATION } from './actions';

export const newMessage = (payload) => ({
  type: NEW_MESSAGE,
  payload
});

export const updateMessage = (payload) => ({
  type: UPDATE_MESSAGES,
  payload
});


export const updateSingleMessage = (payload) => ({
  type: UPDATE_SINGLE_MESSAGES,
  payload
});


export const newNotification = (payload) => ({
  type: NEW_NOTIFICATION,
  payload
});

export const liveChatNotification = (payload) => ({
  type: LIVE_CHAT_NOTIFICATION,
  payload
});
