export const NEW_MESSAGE = 'NEW_MESSAGE';
export const UPDATE_MESSAGES = 'UPDATE_MESSAGES';
export const UPDATE_SINGLE_MESSAGES = 'UPDATE_SINGLE_MESSAGES';
export const RESET_MESSAGES = 'RESET_MESSAGES';
export const UPDATE_TEXT_INPUT = 'UPDATE_TEXT_INPUT';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const START_TYPING = 'START_TYPING';
export const STOP_TYPING = 'STOP_TYPING';
export const RESTART_CHAT = 'RESTART_CHAT';
export const DISABLE_REFRESH = 'DISABLE_REFRESH';
export const ENABLE_REFRESH = 'ENABLE_REFRESH';
export const UPDATE_SKIP_BACK = 'UPDATE_SKIP_BACK';
export const SHOW_ALERT = 'SHOW_ALERT';
export const START_LIVE_CHAT = 'START_LIVE_CHAT';
export const STOP_LIVE_CHAT = 'STOP_LIVE_CHAT';
export const ENABLE_WHATSAPP = 'ENABLE_WHATSAPP';
export const DISABLE_WHATSAPP = 'DISABLE_WHATSAPP';
export const SET_TRANSLATION = 'SET_TRANSLATION';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_WHITE_LABEL = 'SET_WHITE_LABEL';
export const UPDATE_ENVIRONMENT = 'UPDATE_ENVIRONMENT';
export const LOAD_FLOW = 'LOAD_FLOW';
export const UPDATE_META = 'UPDATE_META';
export const TYPING_START = 'TYPING_START';
export const TYPING_STOP = 'TYPING_STOP';
export const USER_TYPING = 'USER_TYPING';
export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
export const LIVE_CHAT_NOTIFICATION = 'LIVE_CHAT_NOTIFICATION';
export const AI_REPLIES = 'AI_REPLIES';
export const AI_KEY_ACTIVE = 'AI_KEY_ACTIVE';
export const ADD_ATTRIBUTE = 'ADD_ATTRIBUTE';
export const ADD_ATTRIBUTES = 'ADD_ATTRIBUTES';
export const AI_THREAD_ID = 'AI_THREAD_ID';
