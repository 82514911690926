
import { ADD_ATTRIBUTE, ADD_ATTRIBUTES } from './actions';

export const addAttribute = (payload) => ({
  type: ADD_ATTRIBUTE,
  payload
});

export const addAttributes = (payload) => ({
  type: ADD_ATTRIBUTES,
  payload
})

