import { STORE } from "../store";
import { Network } from "./network";
import CryptoJS from 'crypto-js';

const network = new Network();

const queryAIHandler = (data) => new Promise((resolve, reject) => {

  const { environment } = STORE.getState();

    network.post(`artificial-intelligence/ai-response`, { binary: encryptPayload({...data, uuid: environment.uuid }) })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(err => { reject(err) })

});

const encryptPayload = (payload) => {
  const newPayload = JSON.stringify(payload);

  return CryptoJS
    .AES
    .encrypt(newPayload, 'user-payload')
    .toString();
};

export { queryAIHandler };