import React from 'react';


import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Bubble from '../Bubble';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { submitAnswer } from '../../utilities/answer';
import { Network } from '../../utilities/network';
import { STORE } from '../../store';
import { newMessage } from '../../actions/message.action';
import { emit } from '../../utilities/socket';
import { saveResponse } from '../../utilities/save-message';
import { focus, scroll } from '../../utilities/shared';
import { v4 as uuid } from 'uuid';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
  },
  loader: {
    margin: 10
  }
});


class File extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false
    };
  }

  upload = (e) => {
    this.setState({
      showLoader: true
    });
    const network = new Network();

    const formData = new FormData();
    formData.append('file', e.target.files[0], e.target.files[0].name);

    network.upload(formData)
      .then(({ data }) => {
        this.setState({
          showLoader: false
        });
        submitAnswer(data)
          .then();
      })
      .catch((err) => {
        if (err.message === 'Failed to fetch') {
          this.sendErrorMessage();
        }
        this.setState({
          showLoader: false
        });
        console.log(err);
      });

  };

  sendErrorMessage = () => {

    const state = STORE.getState();
    let activeQuestion = state.environment.activeQuestion;
    const mid = uuid();

    const message = activeQuestion.message.error || 'File size limit exceeded. Please upload a file smaller than 10MB.';
    STORE.dispatch(newMessage({
      label: message,
      position: 'left',
      type: 'STATEMENT',
      mid
    }));
    emit('message', {
      text: message,
      messageBy: 'bot'
    });
    saveResponse({
      type: 'message',
      text: message,
      messagedBy: 'bot',
      mid
    }).then().catch();
    scroll();
    focus();
  }

  render() {
    const { classes, text } = this.props;
    return (
      <>
        <Bubble avatar={this.props.avatar} text={text || 'Upload a file'} />
        <div style={{ marginLeft: 40 }} className={classes.container}>
          {this.state.showLoader ? <CircularProgress value={50} size={50} className={classes.loader} /> :
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel
                ref={ref => {
                  this.labelRef = ReactDOM.findDOMNode(ref);
                }}
                htmlFor="component-outlined"
              >
              </InputLabel>
              <OutlinedInput
                title={'Upload a file'}
                type={'file'}
                id="component-outlined"
                value={this.state.name}
                onChange={(e) => this.upload(e)}
                labelWidth={this.labelRef ? this.labelRef.offsetWidth : 0}
              />
            </FormControl>}

        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  design: state.design
});

export default connect(mapStateToProps, {})(withStyles(styles)(File));
