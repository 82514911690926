
import { AI_KEY_ACTIVE, AI_REPLIES, AI_THREAD_ID } from './actions';

export const updateAI = (payload) => ({
  type: AI_REPLIES,
  payload
});

export const updateAIKey = () => ({
  type: AI_KEY_ACTIVE
});

export const updateAIThreadId = (payload) => ({
  type: AI_THREAD_ID,
  payload
})
