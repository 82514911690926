import { createStore } from 'redux';
import { reducer } from '../reducers';


const STORE = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

STORE.subscribe(() => {
  if (!window.parent.ShopifyBotData.preview) {
    sessionStorage.setItem(`ShopifyBot-${window.parent.ShopifyBotData._id}`, JSON.stringify(STORE.getState()));
    localStorage.setItem(`ShopifyBot-${window.parent.ShopifyBotData._id}`, JSON.stringify(STORE.getState()));
  }
});

export { STORE };
