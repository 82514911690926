import request from 'request';

class Network {
  host;
  //TODO: add live url later
  // constructor(host = 'http://localhost:8080/') {
  //   this.host = host;
  // }

  constructor(host = `${process.env.REACT_APP_API_URL}/`) {
    this.host = host;
  }

  http = async (url, method, body = null) => {
    return new Promise((resolve, reject) => {
      request(this.host + url, {
        method,
        body,
        json: true,
        withCredentials: true
      }, (error, response, body1) => {
        if (error) {
          reject(error);
        } else {
          if (response.statusCode === 200 || response.statusCode === 201 || response.statusCode === 202) {
            resolve(body1);
          } else {
            reject(body1);
          }
        }
      });
    });
  };
  get = async (url) => this.http(url);
  post = async (url, body) => this.http(url, 'POST', body);
  put = async (url, body) => this.http(url, 'PUT', body);

  upload = async (formData) => new Promise(async (resolve, reject) => {

    try {
      const response = await fetch(this.host + 'upload', {
        body: formData,
        method: 'POST'
      });

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

}

export { Network };
