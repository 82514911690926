import { STORE } from '../store';
import { newMessage } from '../actions/message.action';
import { updateTextInput } from '../actions/text.action';
import { updateEnvironment } from '../actions/environment.action';
import { focus, scroll, sleep, triggerApiComp } from './shared';
import { saveResponse } from './save-message';
import { emit } from './socket';
import Mustache from 'mustache';
import { requestLivechat } from './live-chat';
import { updateAI } from '../actions/ai';
import { v4 as uuid } from 'uuid';
import { addAttribute } from '../actions/attribute';
const keyboard = ['QUESTION', 'EMAIL', 'PHONE', 'NUMBER', 'LOCATION', 'NAME', 'LIVE_CHAT', 'SMART_QUESTION', 'AI'];
const constants = ['STATEMENT', 'IMAGE', 'CONTACT', 'VIDEO'];


function findQuestions(questions, index) {

  return new Promise(async (resolve) => {

    const state = STORE.getState();
    const messages = state.messages;
    let finalQuestions = [];
    let questionsAlreadyAnswered = messages
      .filter(message => !!message.questionId && message.isValid)
      .map(message => message.questionId);

    if (!questions[index]) {
      return resolve(finalQuestions);
    }

    if (constants.includes(questions[index].type.toUpperCase())) {
      finalQuestions = [...finalQuestions, questions[index]];
      if (questions[index].next.target) {
        if (questions[index].next.target.match(/end/)) {
          return resolve(finalQuestions);
        }
        const newIndex = questions.findIndex((ques) => ques.id === questions[index].next.target);
        if (newIndex === -1) {
          finalQuestions = [...finalQuestions, ...await findQuestions(questions, index + 1)];
        } else {
          finalQuestions = [...finalQuestions, ...await findQuestions(questions, newIndex)];
        }
      } else {
        finalQuestions = [...finalQuestions, ...await findQuestions(questions, index + 1)];
      }
    } else {
      if (
        questions[index].skipIfAnswered &&
        questionsAlreadyAnswered.includes(questions[index].id) &&
        ['EMAIL', 'PHONE', 'NAME'].includes(questions[index].type.toUpperCase())
      ) {
        const newIndex = questions.findIndex((ques) => ques.id === questions[index].next.target);
        if (newIndex === -1) {
          finalQuestions = [...finalQuestions, ...await findQuestions(questions, index + 1)];
        } else {
          finalQuestions = [...finalQuestions, ...await findQuestions(questions, newIndex)];
        }

      } else {
        finalQuestions = [...finalQuestions, questions[index]];
      }
    }
    const apiComp = finalQuestions.find(question => question.type === 'api');
    if (apiComp) {
      const nextQuestionId = await triggerApiComp(apiComp);
      const newIndex = questions.findIndex((ques) => ques.id === nextQuestionId);
      const question = finalQuestions.filter(question => question.type !== 'api');
      if (newIndex === -1) {
        finalQuestions = [...question, ...await findQuestions(questions, index + 1)];
      } else {
        finalQuestions = [...question, ...await findQuestions(questions, newIndex)];
      }
    };
    return resolve(finalQuestions);
  });
}


const renderQuestion = (index = 0, aiResponse = '', isRestart = false, suggestions = [], showContactForm = false) => {
  const state = STORE.getState();
  let activeQuestion = state.environment.activeQuestion;
  STORE.dispatch(updateEnvironment({
    typing: false,
    refresh: true,
  }));
  STORE.dispatch(updateTextInput({
    status: false
  }));
  findQuestions(state.flows[0].questions, index).then(async (messages) => {
    if (!messages.length) {
      emit('update-user-details', {
        isCompleted: true
      });
    } else {
      if (messages[messages.length - 1].type.toLowerCase() === 'statement') {
        emit('update-user-details', {
          isCompleted: true
        });
      }
    }

    if (activeQuestion?.type === 'AI' && !isRestart) {
      const aiReplies = state.ai.aiReplies || 0;

      if (activeQuestion?.type === 'AI') {
        let resetAIReplies = false;
        if (aiReplies < (activeQuestion.queriesToHandle || 0)) {

          const finalAiQuestion = aiResponse
            ? [{ ...activeQuestion, label: aiResponse, delay: 0, type: 'AI', suggestions, showContactForm, shouldFeedbackVisible: true, _id: undefined }]
            : [];

          // (aiReplies + 1) means current reply and it is the last AI reply then send further flow along with it
          resetAIReplies = !!aiResponse && ((aiReplies + 1) >= (activeQuestion.queriesToHandle || 0))

          messages = resetAIReplies
            ? [...finalAiQuestion, ...messages]
            : finalAiQuestion;
        }
      }
    }

    if (messages.some((q) => q.type === 'AI' && !!q._id)) {
      STORE.dispatch(updateAI({
        aiReplies: 0
      }));
    } else if (messages.some((q) => q.type === 'AI')) {
      STORE.dispatch(updateAI({
        aiReplies: state.ai.aiReplies + 1
      }));
    }

    for await (let message of messages) {

      message = replaceAttributeWithValueInObject(message, state.attributes);
      let messageText = message.label.replace("{{name}}", sessionStorage.getItem('name') || 'User');
      try {
        messageText = Mustache.render(messageText, state.environment.response || {});
      } catch (e) {
        console.error(e.message);
      }
      STORE.dispatch(updateEnvironment({
        typing: true,
        refresh: false,
        skip: false,
        back: false
      }));
      scroll(100);
      await sleep(Math.abs(message.delay));

      const mid = uuid();
      saveResponse({
        type: 'message',
        text: messageText,
        messagedBy: 'bot',
        isFreeAIMessage: aiResponse && !state.ai?.isKeyActive
      }).then().catch();
      
      // if AI response is contact form, then store empty string as the message to avoid showing the message
      emit('message', {
        text: showContactForm ? "" : messageText,
        messageBy: 'bot'
      });
      STORE.dispatch(newMessage({ ...message, label: showContactForm ? "" : messageText, mid }));
      STORE.dispatch(updateEnvironment({
        activeQuestion: message,
        typing: false,
        activeQuestionType: message.type,
        refresh: true,
        skip: message.skip,
        back: message.back
      }));
      STORE.dispatch(updateTextInput({
        status: keyboard.includes(message.type.toUpperCase())
      }));

      /**
       * Author: Suraj
       * Dated: 28/Oct/2023
       * Commented this code as user will use generic message like "send a message" in placeholder, There is no need of this code for now
       * Reviewed by divyansh
       */
      // if (message.type.toUpperCase() === 'AI') {
      //   STORE.dispatch(updateEnvironment({
      //     chatMenu: { ...state.chatMenu, placeholder: 'Enter your query' }
      //   }));
      // } else {
      //   STORE.dispatch(updateEnvironment({
      //     chatMenu: { ...state.chatMenu, placeholder: '' }
      //   }));
      // }

      scroll(100);
      await sleep();
      focus();
      if (message.type.toUpperCase() === 'LIVE_CHAT') {
        STORE.dispatch(updateEnvironment({
          liveChat: true
        }));
        requestLivechat();
      }
    }

    // Check if user is interacting with AI component and the user text is considered as a neutral text instead of a greeting or query
    if (!messages.length && activeQuestion.type === 'AI') {
      STORE.dispatch(updateTextInput({
        status: true
      }));
    }
  });
};

/**
 * Date: 6/Dec/2023
 * Author: Amit Kumar
 * Summary: This function replaces attribute placeholders with their respective values in an object.
 * Description:
 *
 * Recursively replaces attribute in a given object or array of objects.
 * The attribute keys are replaced based on the provided `inboxUser` and `subscriber` information.
 * The function first checks if the input question is null or undefined.
 * If true, it returns the input unchanged.
 * If the input is a string, the function replaces {{key}} placeholders with corresponding values from the attributes array.
 * It also performs additional specific replacements for known attribute keys :
 * ({{name}}, {{email}}, {{phone}}) based on information from inboxUser and subscriber.
 * If the input is an array, the function applies itself recursively to each element of the array.
 * If the input is an object, the function applies itself recursively to each property of the object.
 * If the input is neither a string, array, nor object, the function returns it unchanged.
 */
const replaceAttributeWithValueInObject = (question, attributes) => {
  if (!question) {
    return question;
  }

  if (typeof question === 'string') {
    let text = question;
    (attributes || []).forEach(item => {
      const value = typeof item?.value === 'string'
        ? item.value
        : JSON.stringify(item.value);

      text = text
        .split(item.key)
        .join(value);
    });


    return text;
  } else if (Array.isArray(question)) {
    return question.map(item => replaceAttributeWithValueInObject(item, attributes));
  } else if (typeof question === 'object') {
    for (const key of Object.keys(question)) {
      question[key] = replaceAttributeWithValueInObject(question[key], attributes);
    }

    return question;
  }

  return question;
};

const setAttributeValue = (activeQuestion, anwer, attributeList) => {
  if (['name', 'email', 'phone'].includes(activeQuestion.type)) {
    STORE.dispatch(addAttribute({
      key: `{{${activeQuestion.type}}}`,
      value: anwer
    }));

  } else if (activeQuestion._attribute) {
    const attribute = attributeList.find(attribute => attribute._id === activeQuestion._attribute);
    if (attribute) {
      STORE.dispatch(addAttribute({
        key: `{{${attribute.key}}}`,
        value: anwer
      }));
    }

  }

}

export { findQuestions, renderQuestion, setAttributeValue };
