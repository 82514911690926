import React from 'react';
import { Card, CardMedia, Paper, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import facebook from '../../assets/icons/facebook.png';
import email from '../../assets/icons/email.png';
import github from '../../assets/icons/github.png';
import instagram from '../../assets/icons/instagram.png';
import link from '../../assets/icons/link.png';
import linkedin from '../../assets/icons/linkedin.png';
import skype from '../../assets/icons/skype.png';
import phone from '../../assets/icons/telephone.png';
import twitter from '../../assets/icons/twitter.png';
import youtube from '../../assets/icons/youtube.png';
import whatsapp from '../../assets/icons/whatsapp.png';
import messenger from '../../assets/icons/messenger.png';
import telegram from '../../assets/icons/telegram.png';
import snapchat from '../../assets/icons/snapchat.png';
import Typography from '@material-ui/core/Typography';
// import ReactPlayer from 'react-player';
// import ImageAvatars from '../Avatar';


const styles = theme => ({
  root: {
    padding: theme.spacing(1, 6),
    width: 'fit-content',
    maxWidth: '60%',
    wordBreak: 'break-word'
  },
  left: {
    borderRadius: '20px 20px 20px 2px',
    color: '#000000',
    background: 'rgb(245, 245, 245)',
    position: 'relative'
  },
  right: {
    borderRadius: '20px 20px 2px 20px',
    marginRight: 10,
    marginLeft: 'auto',
    background: theme.palette.primary.main,
    color: theme.palette.text.primary
  },
  div: {
    display: 'flex'
  },
  // image: {
  //   height: 20,
  //   padding: 0,
  //   margin: 'auto',
  //   verticalAlign: 'middle'
  // },
  card: {
    margin: 10,
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
  },
  button: {
    margin: 2,
    borderRadius: 10,
    paddingRight: 15,
    textTransform: 'none',
  },
  icon: {
    width: 20
  }
});

class Suggestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttons: ['option 1', 'option 2', 'option 3', 'option 4', 'option 5', 'option 6'],
      show: true,
      icon: {
        email,
        github,
        instagram,
        link,
        linkedin,
        skype,
        phone,
        twitter,
        youtube,
        whatsapp,
        messenger,
        facebook,
        telegram,
        snapchat
      },
      links: {
        email: 'mailto:',
        github: 'https://github.com/',
        instagram: 'https://www.instagram.com/',
        link: '',
        linkedin: 'https://www.linkedin.com/in/',
        skype: 'https://join.skype.com/invite /',
        phone: 'tel:',
        twitter: 'https://twitter.com/',
        youtube: '',
        whatsapp: 'https://wa.me/',
        messenger: 'https://m.me/',
        facebook: 'https://facebook.com/',
        telegram: 'https://t.me/',
        snapchat: 'https://snapchat.com/add/'
      }
    };
    this.handleClick = this.handleClick.bind(this);
  }

  selectOption = (link, type) => {
    // window.open(this.state.links[type] + link, '_blank');
  };

  handleClick(value) {
    this.props.onValueChange(value);
  }


  render() {
    const { classes, text, buttons } = this.props;
    return (
      <>
        { buttons.length > 0 && <div className={classes.div}>
          <Paper elevation={0} className={`${classes.root}`} style={{ background: 'none' }}>
            {/* <Typography component="pre" style={{ whiteSpace: 'pre-wrap' }}> {text} </Typography> */}
            {
              (buttons || []).map((button, index) => (
                <Button
                  onClick={(e) => {
                    this.handleClick(button.title);
                  }}
                  key={index} variant="outlined" color="primary"
                  aria-label="add"
                  className={classes.button} size={'small'} 
                  // style={{ color: 'black' }}
                >
                  {button.title ?? "How are you"}
                </Button>))
            }

          </Paper>
        </div>}
      </>
    );
  }
}

export default connect(null, {})(withStyles(styles)(Suggestion));
