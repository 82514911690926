import { LOAD_FLOW } from './actions';


const originalFlows = undefined;

let flows = sessionStorage.getItem( `ShopifyBot-${ window.parent.ShopifyBotData._id }` );



if ( flows ) {
  try {
    flows = JSON.parse( flows ).flows || originalFlows;
  } catch ( e ) {
    flows = originalFlows;
  }
}


export const loadFlow = () => ( {
  type: LOAD_FLOW,
  payload: flows || window.parent.ShopifyBotData.flows
} );
