import React from 'react';
import Bubble from '../Bubble';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { submitAnswer } from '../../utilities/answer';


const styles = () => ( {
  margin: {
    margin: 2,
    borderRadius: 20,
    textTransform: 'none'
  },

} );

class SingleSelect extends React.Component {
  constructor( props ) {
    super( props );
    this.state = {
      buttons: [ 'option 1', 'option 2', 'option 3', 'option 4', 'option 5', 'option 6' ],
      show: true
    };
  }

  selectOption = ( value, next ) => {
    submitAnswer( value, value, next );
    // this.props.updateMessages();
    // this.props.newMessage({
    //   type: 'STATEMENT',
    //   payload: {
    //     text: name
    //   },
    //   position: 'right'
    // });
    // scroll();
    // communicate({
    //   ...this.props.config,
    //   text: name,
    //   event: 'continuous'
    // }).then(async ({response}) => {
    //   }
    // ).catch(console.log);
  };


  render() {
    const { classes, text } = this.props;
    return (
      <>
        <Bubble avatar={ this.props.avatar } text={ text || 'Select a button' } theme={ this.props.theme }
                color={ this.props.color }/>
        <div style={ { marginLeft: 40 } }>
          { this.props.buttons.map( ( button, index ) => (
            <Button onClick={ ( e ) => {
              this.selectOption( button.value, button.next.target || null );
            } } key={ index } variant="outlined" color="primary"
                    aria-label="add"
                    className={ classes.margin } size={ 'medium' }>
              <Typography>
                { button.value }
              </Typography>
            </Button> ) ) }
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ( {} );


export default connect( mapStateToProps, {} )( withStyles( styles )( SingleSelect ) );
