import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import ImageAvatars from '../Avatar';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined';
import { saveMessageFeedback } from '../../utilities/save-message';
import { Button } from '@material-ui/core';
import { requestLivechat } from '../../utilities/live-chat';
import { STORE } from '../../store';
import { updateEnvironment } from '../../actions/environment.action';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2),
    margin: 10,
    marginLeft: 0,
    marginRight: 0,
    width: 'fit-content',
    maxWidth: '60%',
    wordBreak: 'break-word'
  },
  left: {
    borderRadius: '20px 20px 20px 2px',
    color: '#000000',
    background: 'rgb(245, 245, 245)',
    position: 'relative'
  },
  right: {
    borderRadius: '20px 20px 2px 20px',
    marginRight: 10,
    marginLeft: 'auto',
    background: theme.palette.primary.main,
    color: theme.palette.text.primary
  },
  div: {
    display: 'flex'
  },
  image: {
    height: 20,
    padding: 0,
    margin: 'auto',
    verticalAlign: 'middle'
  },
  feedback: {
    width: '60px',
    height: '40px',
    position: 'absolute',
    right: '10px',
    bottom: '-30px',
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'space-around'
  },
  marginTop: {
    marginTop: 10
  }
}));

export default function ModifiedBubble(props) {
  const [hasClickedThumbsUp, setHasClickedThumbsUp] = React.useState(props.hasClickedThumbsUp || false);
  const [hasClickedThumbsDown, setHasClickedThumbsDown] = React.useState(props.hasClickedThumbsDown || false);
  const [buttons, setButtons] = React.useState({});
  const [recommendedProducts, setRecommendedProducts] = React.useState(false);

  const classes = useStyles();
  let { position, text, shouldFeedbackVisible, mid } = props;

  useEffect(() => {
    if (text) {
      const showButtons = {};
      if (text.toLowerCase().indexOf("live support agent") > -1) {
        showButtons.liveChat = true;
      }
      setButtons(showButtons);
      try {
        let data = JSON.parse(text);
        if (data.type === 'shopify') {
          setRecommendedProducts(true);
        }
      } catch (e) {
        setRecommendedProducts(false);
      }
    }
  }, [text]);

  const renderTextWithLinks = (text) => {
    try {
      let data = JSON.parse(text);
      if (data.type === 'shopify') {
        const htmlElement = data.body[0];
        return { __html: htmlElement };
      }
    } catch (e) {

    }
    const urlRegex = /(?:(?![\w.+-]+@[\w-]+\.[\w-.]+)(?:https?:\/\/(?:www\.)?\S+)|(?:www\.\S+))/g;
    const textWithLinks = text.replace(urlRegex, (url) => {
      if (!url.match(/^https?:\/\//i)) {
        url = 'https://' + url;
      }
      url = url.replace(/\.$/, '');

      return `<a href="${url}" target="_blank">${url}</a>`;
    });
    return { __html: textWithLinks };
  };

  const submitFeedback = (data) => {
    saveMessageFeedback(data)
      .catch(error => console.log(error));
  }

  const handleLikes = (type) => {
    if (type === "thumbsUp") {
      setHasClickedThumbsUp(true);
      setHasClickedThumbsDown(false);
      submitFeedback({ mid, hasClickedThumbsUp: true, hasClickedThumbsDown: false })
    } else {
      setHasClickedThumbsUp(false);
      setHasClickedThumbsDown(true);
      submitFeedback({ mid, hasClickedThumbsUp: false, hasClickedThumbsDown: true })
    }
  }

  const startLiveChat = () => {
    STORE.dispatch(updateEnvironment({
      liveChat: true
    }));
    requestLivechat();
  }

  return (

    <div className={classes.div}>
      {position === 'right' ? '' : <ImageAvatars avatar={props.avatar} small={true} />}
      <Paper elevation={0} className={clsx(classes.root, position === 'right' ? classes.right : classes.left)} >

        {!recommendedProducts && <>
          {(position !== 'right' && shouldFeedbackVisible) && (
            <div className={classes.feedback}>
              {
                hasClickedThumbsUp
                  ?
                  <ThumbUpIcon
                    style={{ color: '#707070', cursor: 'pointer', width: '16px' }}
                    onClick={() => handleLikes("thumbsUp")} ></ThumbUpIcon>
                  :
                  <ThumbUpAltOutlinedIcon style={{ color: '#999999', cursor: 'pointer', width: '16px' }}
                    onClick={() => handleLikes("thumbsUp")} ></ThumbUpAltOutlinedIcon>
              }

              {
                hasClickedThumbsDown
                  ?
                  <ThumbDownIcon
                    style={{ color: '#707070', cursor: 'pointer', width: '16px' }}
                    onClick={() => handleLikes("thumbsDown")} ></ThumbDownIcon>
                  :
                  <ThumbDownAltOutlinedIcon style={{ color: '#999999', cursor: 'pointer', width: '16px' }}
                    onClick={() => handleLikes("thumbsDown")} ></ThumbDownAltOutlinedIcon>
              }

            </div>
          )}

          {shouldFeedbackVisible
            ? <Typography component="pre" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={renderTextWithLinks(text)} />
            : <Typography component="pre" style={{ whiteSpace: 'pre-wrap' }}> {text} </Typography>
          }
          {buttons.liveChat && <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.marginTop}
            onClick={startLiveChat}
          >Live Chat</Button>}
        </>}

        {recommendedProducts && <Typography component="pre" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={renderTextWithLinks(text)} />}

      </Paper>
    </div>
  );
}
