import { UPDATE_TEXT_INPUT } from '../actions/actions';


let text = window.parent.ShopifyBotData.configuration?.generalSettings?.userChatRetention === 'forever'
  ? localStorage.getItem(`ShopifyBot-${window.parent.ShopifyBotData._id}`)
  : sessionStorage.getItem(`ShopifyBot-${window.parent.ShopifyBotData._id}`);

if (text) {
  try {
    text = JSON.parse(text).text || {
      status: false,
      value: '',
      original: ''
    };
  } catch (e) {
    text = {
      status: false,
      value: '',
      original: ''
    };
  }
}

const textInputReducer = (state = text || {
  status: false,
  value: '',
  original: ''
}, action) => {
  switch (action.type) {
    case UPDATE_TEXT_INPUT: {
      return {
        ...state,
        ...action.payload,
        original: action.payload.original || action.payload.value || ''
      };
    }
    default: {
      return state;
    }
  }
};

export { textInputReducer };
