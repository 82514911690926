import { STORE } from '../store';
import { updateEnvironment } from '../actions/environment.action';
import { Network } from './network';
import { newNotification, liveChatNotification } from '../actions/message.action';
import { renderQuestion } from '../utilities/question';
import { scroll } from './shared';
import { updateTextInput } from '../actions/text.action';
const uuid = localStorage.getItem('ShopifyBot_User_uuid');
let expireTimer;
const state = STORE.getState();
const network = new Network();

const requestLivechat = () => {

  network.put('live-chat-request', {
    uuid: localStorage.getItem('ShopifyBot_User_uuid'),
    _bot: localStorage.getItem('__ShopifyBot'),
    _customer: state.environment._user,
    _user: state.environment._chatWindowUser,
    emails: state.configuration?.alertSettings?.email?.emails,
    botName: state.design.name,
    _parent: state.environment.agencyData._id
  })
    .then(() => {
      STORE.dispatch(updateEnvironment({
        liveChat: true,
        disableStartButton: true,
        typing: false,
      }));
      STORE.dispatch(liveChatNotification({
        title: 'Initiating Live Chat...',
        text: state.configuration.generalSettings.waitingMessage,
        color: 'blue',
        type: 'LIVE_CHAT_NOTIFICATION'
      }));
      STORE.dispatch(updateTextInput({
        status: false,
        value: ''
      }));
      expireTimer = setTimeout(() => {
        expireLiveChatRequest(uuid);
        STORE.dispatch(liveChatNotification({
          title: 'Live chat is Unavailable!!',
          text: state.configuration.generalSettings.unavailabilityMessage,
          color: 'orange',
          type: 'LIVE_CHAT_NOTIFICATION'
        }));
        renderNextQuestion();
      }, 120000);
      scroll();
    }).catch();
}

const clearRequestTimer = () => {
  if (expireTimer) {
    clearTimeout(expireTimer);
  }
}

const expireLiveChatRequest = async (uuid) => {
  await
    network.put(`expire-chat-request/${uuid}`, {})
      .then(() => {
        STORE.dispatch(updateEnvironment({
          liveChatExpired: true,
          chatRequestRejected: true,
          chatRequestAccepted: false,
          liveChat: false,
          initiateLiveChat: false,
          disableStartButton: false,
          agentId: undefined
        }));
        // STORE.dispatch(liveChatNotification({
        //   title: 'Live chat is Unavailable!!',
        //   text: state.configuration.generalSettings.unavailabilityMessage,
        //   color: 'orange',
        //   type: 'LIVE_CHAT_NOTIFICATION'
        // }));
        scroll();
        localStorage.removeItem('agentId');
        localStorage.removeItem('bp-wsn');
      }).catch();

}

const transferToBot = async (isRestart = false) => {
  if (localStorage.getItem('agentId')) {
    await network.put('bot-update-handler/bot',
      {
        uuid: state.environment.uuid,
        transferredBy: "USER",
        _transferrer: state.environment._chatWindowUser,
        agentId: localStorage.getItem('agentId'),
      })
      .then(async () => {
        await renderQuestion(0, '', isRestart);
        localStorage.removeItem('agentId');
        localStorage.removeItem('bp-wsn');
        STORE.dispatch(newNotification({
          label: 'You have transferred the chat back to bot',
          type: 'NEW_NOTIFICATION'
        }));
        STORE.dispatch(updateEnvironment({
          liveChat: false,
          chatRequestAccepted: false,
          disableStartButton: false,
          agentId: undefined,
          enableTextInput: true,
        }));
        localStorage.removeItem('agentId');
      }).catch();
  } else {
    clearRequestTimer();
    renderQuestion(0, '', isRestart);
    if (!localStorage.getItem('agentId')) {
      expireLiveChatRequest(state.environment.uuid);
    }
    STORE.dispatch(updateEnvironment({
      liveChat: false,
      chatRequestAccepted: false,
      disableStartButton: false,
      agentId: undefined,
    }));
    localStorage.removeItem('agentId');
    localStorage.removeItem('bp-wsn');
  }

}

const transferToWhatsapp = async () => {
  if (localStorage.getItem('agentId')) {
    await
      network.put('bot-update-handler/whatsapp',
        {
          uuid: state.environment.uuid,
          transferredBy: "USER",
          _transferredTo: localStorage.getItem('agentId'),
          agentId: localStorage.getItem('agentId'),
        })
        .then(() => {
          STORE.dispatch(newNotification({
            label: 'You have transferred the chat to Whatsapp',
            type: 'NEW_NOTIFICATION'
          }));
          STORE.dispatch(updateEnvironment({
            chatRequestRejected: false,
            chatRequestAccepted: false,
            liveChat: false,
            disableStartButton: false,
            agentId: undefined,
            typing: false,
          }));
          STORE.dispatch(updateTextInput({
            status: false,
            value: ''
          }));
          window.open(
            `https://wa.me/` + localStorage.getItem('bp-wsn'),
            '_blank' // <- This is what makes it open in a new window.
          );
          localStorage.removeItem('agentId');
          localStorage.removeItem('bp-wsn');

          scroll();

        }).catch();
  }
}

const renderNextQuestion = () => {

  const stateData = STORE.getState();
  const activeQuestion = stateData.environment.activeQuestion;
  const activeQuestionIndex = (stateData.flows[0].questions || []).findIndex(question => question.id === activeQuestion.id);
  // Code for rendering next question if active questin does not have a target next defined else send next index message
  if (activeQuestion.next && activeQuestion.next.target) {
    const nextQuestionIndex = (stateData.flows[0].questions || []).findIndex(question => question.id === activeQuestion.next.target);
    renderQuestion(nextQuestionIndex);
  } else {
    renderQuestion(activeQuestionIndex + 1);
  }

}

export { renderNextQuestion, requestLivechat, expireLiveChatRequest, transferToBot, transferToWhatsapp, clearRequestTimer };