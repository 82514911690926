import { AI_KEY_ACTIVE, AI_REPLIES, AI_THREAD_ID } from '../actions/actions';

const initialState = { aiReplies: 0, isKeyActive: false, threadId: window.parent.ShopifyBotData?.threadId || '' }

const aiReducer = (state = initialState, action) => {
  if (action.type === AI_REPLIES) {
    console.log('action.payload.suggestion', action.payload);
    return { ...state, aiReplies: action.payload.aiReplies, suggestion: action.payload.suggestion, showContactForm: action.payload.showContactForm  };
  }
  if (action.type === AI_KEY_ACTIVE) {
    return { ...state, isKeyActive: true };
  }
  if (action.type === AI_THREAD_ID) {
    return { ...state, threadId: action.payload.threadId };
  }
  return state;
};

export { aiReducer };
