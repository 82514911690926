import { NEW_MESSAGE, UPDATE_MESSAGES, UPDATE_SINGLE_MESSAGES, NEW_NOTIFICATION, LIVE_CHAT_NOTIFICATION } from '../actions/actions';

const excludes = ['SUGGESTION', 'IMAGE', 'VIDEO', 'CONTACT', 'PRODUCT', 'NEW_NOTIFICATION', 'LIVE_CHAT_NOTIFICATION'];
let localMessages = window.parent?.ShopifyBotData?.configuration?.generalSettings?.userChatRetention === 'forever'
  ? localStorage.getItem(`ShopifyBot-${window.parent.ShopifyBotData._id}`)
  : sessionStorage.getItem(`ShopifyBot-${window.parent.ShopifyBotData._id}`);

if (localMessages) {
  try {
    localMessages = JSON.parse(localMessages).messages || [];
  } catch (e) {
    localMessages = [];
  }
}

const messageReducer = (state = localMessages || [], action) => {
  switch (action.type) {
    case NEW_MESSAGE: {
      return [...state, { ...action.payload }];
    }
    case UPDATE_MESSAGES: {
      return [...state.map(message => {
        if (!excludes.includes(message.type.toUpperCase())) {
          message.type = 'STATEMENT';
          message.back = false;
          message.skip = false;
        }
        return message;
      })];
    }
    case UPDATE_SINGLE_MESSAGES: {
      return [...state.map(message => {
        if (message.mid === action.payload.mid) {
          return action.payload;
        }
        
        return message;
      })];
    }
    case NEW_NOTIFICATION: {
      let message = {
        label: action.payload.label,
        type: 'NEW_NOTIFICATION'
      }
      return [...state, message];
    }
    case LIVE_CHAT_NOTIFICATION: {
      let message = {
        ...action.payload,
        type: 'LIVE_CHAT_NOTIFICATION'
      }
      return [...state, message]
    }
    default: {
      return state;
    }
  }
};

export { messageReducer };
